import './HeroSection3.css';

import { Button } from './Button';
import { Link } from 'react-router-dom';
import React from 'react';
import SimpleMap from './SimpleMap';

function HeroSection3({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  description2,
  description3,
  description4,
  description5,
  buttonLabel,
  imgStart
}) {

  const [textContent, settextContent] = React.useState(null)
    const [copied, setCopied] = React.useState(false)

   const copyToClipboard = async e => {
      window.getSelection().removeAllRanges();
      var range = document.createRange();
      range.selectNode(textContent);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      setCopied( true );
    };

    
  const initRef = c => {settextContent(c)};

  return (
    <>
      <div
        className={lightBg ? 'home__hero-section3' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col3'>
              <div className='home__hero-text-wrapper'>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <div className='aRow'>
                  <p
                    className={
                      lightTextDesc
                        ? 'home__hero-subtitle'
                        : 'home__hero-subtitle dark'
                    }
                    style={{marginRight:5}}
                  >
                    Telefon:   
                  </p>
                  <a 
                    className={
                        lightTextDesc
                          ? 'home__hero-subtitle'
                          : 'home__hero-subtitle dark'
                      }
                    href='tel:+02242491294'
                  >
                  {description}
                  </a>
                </div>
                <div className='aRow'>
                  <p
                    className={
                      lightTextDesc
                        ? 'home__hero-subtitle'
                        : 'home__hero-subtitle dark'
                    }
                    style={{marginRight:5}}
                  >
                    Cep Telefonu:  
                  </p>
                  <a 
                    className={
                        lightTextDesc
                          ? 'home__hero-subtitle'
                          : 'home__hero-subtitle dark'
                      }
                    href='tel:+905358622824'
                  >
                  {description2}
                  </a>
                </div>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description3}
                </p>
                <div className='aRow'>
                  <p
                    className={
                      lightTextDesc
                        ? 'home__hero-subtitle'
                        : 'home__hero-subtitle dark'
                    }
                    style={{marginRight:5}}
                  >
                    E-mail:  
                  </p>
                  <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                  onClick={copyToClipboard} ref={initRef}
                >
                  {description4}
                </p>
                </div>
                
                {copied && <p className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }>E-mail kopyalandı.</p>}
              
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description5}
                </p>
              </div>
              <a href='https://www.google.com/maps/place/Ahtapot+Marin/@40.2374466,28.9811451,14z/data=!4m8!1m2!2m1!1sahtapot+mar%C4%B1n!3m4!1s0x14ca15be90daaafd:0x997f172cb7dce5e8!8m2!3d40.2218053!4d29.0193581'>
                  <Button buttonSize='btn--wide' buttonColor='blue'>
                    {buttonLabel}
                  </Button>
              </a>
            </div>
            <div className='col3'>
              <div className='home__hero-img-wrapper3'>
                <SimpleMap/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection3;
