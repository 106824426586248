// in app.js

import * as React from "react";
import * as firebase from 'firebase';

import { Admin, Resource } from 'react-admin';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import {
  PostCreate,
  PostEdit,
  PostList,
  galleryPostCreate,
  galleryPostEdit,
  galleryPostList,
  motorPostCreate,
  motorPostEdit,
  motorPostList,
  sparePartsPostCreate,
  sparePartsPostEdit,
  sparePartsPostList
} from './posts';

import firebaseDataProvider  from 'ra-data-firebase-client';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import turkishMessages from 'ra-language-turkish'

const i18nProvider = polyglotI18nProvider(() => turkishMessages, 'tr');



const config = {
    apiKey: "AIzaSyC4u5efevCef1ekBS48b9gqScCIC9aKtSY",
    authDomain: "ahtapotmarin-b51ff.firebaseapp.com",
    databaseURL: "https://ahtapotmarin-b51ff.firebaseio.com",
    projectId: "ahtapotmarin-b51ff",
    storageBucket: "ahtapotmarin-b51ff.appspot.com",
    messagingSenderId: "568201808573",
    appId: "1:568201808573:web:0e947c79c113377aac45d4",
    measurementId: "G-WTGS3EL2W6"
};

function AdminPage({getApp}) {
  const options = {
  app: getApp,
  softDelete: false,
};

const authProvider = FirebaseAuthProvider(config, options);
const dataProvider = FirebaseDataProvider(config, options);

//dataProviderFirebase.getOne('products', { id: 1 }).then(response => console.log(response));
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider} locale="tr" i18nProvider={i18nProvider}>
        <Resource name="tekne" list={PostList} edit={PostEdit} create={PostCreate}/>
        <Resource name="motor" list={motorPostList} edit={motorPostEdit} create={motorPostCreate}/>
        <Resource name="galeri" list={galleryPostList} edit={galleryPostEdit} create={galleryPostCreate}/>
        <Resource name="yedek-parca" list={sparePartsPostList} edit={sparePartsPostEdit} create={sparePartsPostCreate}/>
    </Admin>
  );
}

export default AdminPage;