import * as React from "react";

import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    ImageInput,
    List,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const redirect = (basePath, id, data) => `/author/${data.author_id}/show`;

const PostFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
    </Filter>
  );

export const PostList = (props) => (
    <List {...props} filters={<PostFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="image" src='src' title="title" />
            <TextField source="tekneMarka" />
            <TextField source="tekneAciklama" />
            <TextField source="tekneModel" />
            <TextField source="tekneUzunlugu" />
            <TextField source="motorAciklama" />
            <TextField source="motorMarka" />
            <TextField source="motorBeygir" />
            <TextField source="motorModel" />
            <TextField source="motorSaati" />
            <TextField source="fiyat" />
            <EditButton basePath="/tekne" />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);


export const motorPostList = (props) => (
    <List {...props} filters={<PostFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="image" src='src' title="title" />
            <TextField source="motorMarka" />
            <TextField source="motorBeygir" />
            <TextField source="motorAciklama" />
            <TextField source="motorModel" />
            <TextField source="motorSaati" />
            <TextField source="fiyat" />
            <EditButton basePath="/motor" />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const galleryPostList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="image.src" title="title" />
            <EditButton basePath="/galeri" />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const sparePartsPostList = (props) => (
    <List {...props} filters={<PostFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="image.src" title="title" />
            <TextField source="yedekParcaBaslik" />
            <TextField source="yedekParcaAciklama" />
            <EditButton basePath="/yedek-parca" />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

const PostTitle = ({ record }) => {
    return <span>Post {record ? `"${record.id}"` : ''}</span>;
};

export const PostEdit = (props) => (
    <Edit undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ya da birden fazla resim ekle." accept="image/*" multiple={true}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="tekneMarka" />
            <TextInput source="tekneAciklama" />
            <TextInput source="tekneModel" />
            <TextInput source="tekneUzunlugu" />
            <BooleanInput label="Motor" source="motor" />
            <TextInput source="motorAciklama" />
            <TextInput source="motorMarka" />
            <TextInput source="motorBeygir" />
            <TextInput source="motorModel" />
            <TextInput source="motorSaati" />
            <TextInput source="fiyat" />
        </SimpleForm>
    </Edit>
);

export const motorPostEdit = (props) => (
    <Edit undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ya da birden fazla resim ekle." accept="image/*" multiple={true}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="motorMarka" />
            <TextInput source="motorBeygir" />
            <TextInput source="motorAciklama" />
            <TextInput source="motorModel" />
            <TextInput source="motorSaati" />
            <TextInput source="fiyat" />
        </SimpleForm>
    </Edit>
);

export const galleryPostEdit = (props) => (
    <Edit undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />

            <ImageInput source="image" label="Image" placeholder="Resim ekle." accept="image/*">                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const sparePartsPostEdit = (props) => (
    <Edit undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <ImageField source="image.src" title="title" />
            <TextInput source="yedekParcaBaslik" />
            <TextInput source="yedekParcaAciklama" />
        </SimpleForm>
    </Edit>
);

export const PostCreate = (props) => (
    <Create title="Yeni Post Gönder" {...props}>
    <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ya da birden fazla resim ekle." accept="image/*" multiple={true}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="tekneMarka" />
            <TextInput source="tekneAciklama" />
            <TextInput source="tekneModel" />
            <TextInput source="tekneUzunlugu" />
            <BooleanInput label="Motor" source="motor" defaultValue={true} />
            <TextInput source="motorAciklama" />
            <TextInput source="motorMarka" />
            <TextInput source="motorBeygir" />
            <TextInput source="motorModel" />
            <TextInput source="motorSaati" />
            <TextInput source="fiyat" />
        </SimpleForm>
    </Create>
);

export const motorPostCreate = (props) => (
    <Create title="Yeni Post Gönder" {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ya da birden fazla resim ekle." accept="image/*" multiple={true}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="motorMarka" />
            <TextInput source="motorBeygir" />
            <TextInput source="motorAciklama" />
            <TextInput source="motorModel" />
            <TextInput source="motorSaati" />
            <TextInput source="fiyat" />
        </SimpleForm>
    </Create>
);

export const galleryPostCreate = (props) => (
    <Create title="Yeni Post Gönder" {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ekle." accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);


export const sparePartsPostCreate = (props) => (
    <Create title="Yeni Post Gönder" {...props}>
    <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <ImageInput source="image" label="Image" placeholder="Resim ekle." accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="yedekParcaBaslik" />
            <TextInput source="yedekParcaAciklama" />
        </SimpleForm>
    </Create>
);