export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Ahtapot',
  headline: 'Her Kullanımdan Önce',
  description:
    '1. Emniyet kablosunun motoru durdurduğunu kontrol edin.',
  description2:
    '2. Benzin sisteminin bağlantılarını kontrol edin.',
  description3:
    '3. Motorun tekneye bağlantılarını kontrol edin.',
  description4:
    '4. Direksiyon sistemini kontrol edin.',
  description5:
    '5. Pervaneyi kontrol edin.',
  imgStart: '',
  img: 'images/infoBackground.jpg',
  alt: 'Kontrol Listesi'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Ahtapot',
  headline: 'Her Kullanımdan Sonra',
  description:
    '1. Soğutma suyunun boşaltın.',
  description2:
    '2. Eğer tuzlu suda kullanıldıysa tatlı suyla motoru silin.',
  imgStart: 'start',
  img: 'images/infoBackground2.jpg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Ahtapot',
  headline: 'Her 100 Saatte Bir Ya da Her Senede Bir',
  description:
    '1. Bütün yağları boşaltılarak yenileri koyun.',
  description2:
    '2. Bujileri temizleyin.',
  description3:
    '3. Karbüratörlü modellerde benzin filtresini kontrol edin.',
  description4:
    '4. EFI modellerde benzin suyu filtresini değişin.',
  description5:
    '5. Anodları kontrol edin.',
  description6:
    '6. Hidrolik Trim yağını kontrol edin.',
  description7:
    '7. Gerekliyse karbüratörleri ayarlatın.',
  description8:
    '8. Aküyü kontrol edin ve suyu eksikse ekleyin.',
  description9:
    '9. Distribütör ayarını kontrol ettirin.',
  description10:
    '10. Kontrol kablolarının ayarını kontrol edin.',
    description11:
    '11. Alternatör kayışının gerginliğini kontrol edin.',
  description12:
    '12. Motordaki tortuları OMC Temizleyici ile temizleyin.',
  description13:
    '13. Tüm civata ve vidaları kontrol edin.',
  imgStart: '',
  img: 'images/infoBackground3.jpg',
  alt: 'Vault'
};
