import './Navbar.css';

import { FaBars, FaTimes } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import { Button } from './Button';
import { GiOctopus } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false)
    window.scrollTo(0, 0);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    click && (document.body.style.overflow = 'hidden');
    !click && (document.body.style.overflow = 'unset');
 }, [click ]);

  window.addEventListener('resize', showButton);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar active'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src='images/Ahtapot-Marin-Logo.png' alt='Ahtapot Marin Logo' className='ahtapotlogo' />

            </Link>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link
                  to='/urunler'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  2. El Ürünler
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/yedek-parca'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Yedek Parça
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/galeri'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Galeri
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/faydali-bilgiler'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Faydalı Bilgiler
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/iletisim'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  İletişim
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
