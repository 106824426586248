import './Cards.css';

import CardItem from './CardItem';
import React from 'react';

function Cards({list}) {
  
  const rows = list.reduce((rows, item, index) => {
    return (index % 2 == 0 ? 
      rows.push([item]) : rows[rows.length-1].push(item)) && rows;
  }, []);
  
  console.log(rows)

  return (
    <div className='cards'>
      <h1>Ahtapot Marin yedek parça listesi</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          {rows.map((rows) => {
            return <ul className='cards__items'>
              {rows.map(col => {
                 return <CardItem
                    src={col.image.src}
                    text={col.yedekParcaAciklama}
                    label={col.yedekParcaBaslik}
                  />
              })}
            </ul>
          })}
            
        </div>
      </div>
    </div>
  );
}

export default Cards;
