export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'SIGN UP TODAY',
  headline: 'İletişim ve Adres Bilgilerimiz',
  description: '0 (224) 249 12 94',
  description2: '0 (535) 862 28 24',
  description3: 'Fax: 0 (224) 249 12 94',
  description4: 'idriscol@ahtapotmarin.com',
  description5:
    'Adres: Ahtapot Deniz Motorları Bakım Servisi Mehmet Akif Mah. Trafo Cad. No: 21/C Bursa',
  buttonLabel: 'Yol Tarifi',
  imgStart: '',
  img: 'images/svg-6.svg',
  alt: 'Credit Card'
};