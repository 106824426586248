import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from './Data';

import HeroSection from '../../HeroSection';
import HeroSection2 from '../../HeroSection2';
import Navbar from '../../Navbar';
import Pricing from '../../Pricing';
import React from 'react';

function UsefulInfo() {
  return (
    <>
      <Navbar />
      <HeroSection2 {...homeObjOne} />
      <HeroSection2 {...homeObjTwo} />
      <HeroSection2 {...homeObjThree} />
    </>
  );
}

export default UsefulInfo;
