import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from './Data';

import HeroSection from '../../HeroSection';
import HeroSection3 from '../../HeroSection3';
import Navbar from '../../Navbar';
import Pricing from '../../Pricing';
import React from 'react';

function Contact() {
  return (
    <>
      <Navbar />
      <HeroSection3 {...homeObjOne} />
    </>
  );
}

export default Contact;
