import './App.css';
import "animate.css/animate.min.css";
import 'firebase/storage';

import * as firebase from 'firebase';

import React, {useState} from 'react';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import AdminPage from './components/pages/Admin/AdminPage';
import Contact from './components/pages/Contact/Contact';
import Footer from './components/pages/Footer.js/Footer';
import Gallery from './components/pages/Gallery/Gallery';
import Home from './components/pages/HomePage/Home';
import Products from './components/pages/Products/Products';
import ScrollToTop from './components/ScrollToTop';
import SpareParts from './components/pages/SpareParts/SpareParts';
import UsefulInfo from './components/pages/UsefulInfo/UsefulInfo';

const config = {
    apiKey: "AIzaSyC4u5efevCef1ekBS48b9gqScCIC9aKtSY",
    authDomain: "ahtapotmarin-b51ff.firebaseapp.com",
    databaseURL: "https://ahtapotmarin-b51ff.firebaseio.com",
    projectId: "ahtapotmarin-b51ff",
    storageBucket: "ahtapotmarin-b51ff.appspot.com",
    messagingSenderId: "568201808573",
    appId: "1:568201808573:web:0e947c79c113377aac45d4",
    measurementId: "G-WTGS3EL2W6"
};

const firebaseAppInit = firebase.initializeApp(config);
const storage = firebase.storage();
firebase.analytics();

export function getApp(){
  return firebaseAppInit; 
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/urunler' component={Products} getApp={getApp}/>
          <Route path='/yedek-parca' component={SpareParts}/>
          <Route path='/galeri' component={Gallery} />
          <Route path='/faydali-bilgiler' component={UsefulInfo} />
          <Route path='/iletisim' component={Contact} />
          <Route path='/admin' component={AdminPage} getApp={getApp} />
        </Switch>      
        <Footer />
    </Router>
  );
}

export default App;