import * as firebase from 'firebase';

import React, {useEffect} from 'react';

import Cards from '../../Cards'
import Navbar from '../../Navbar';

function SpareParts() {

  let db = firebase.firestore();

  const [list, setList] = React.useState([]);

  const [s, setS] = React.useState(false);

  function getData() {

    const snapshot = db.collection('yedek-parca').get().then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());

      
      setS(true);
      setList(data);
    });

  }
  
  const fetchData = async () => {

      await getData();
      
    console.log(list);
  };



  console.log(list)

  useEffect(() => {

    fetchData();
    
  }, [s]);


  return (
    <>
      <Navbar />
      <Cards list={list} />
    </>
  );
}

export default SpareParts;
