export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Detaylı motor bakımı',
  description:
    'Ahtapot Deniz Motorları Servisi olarak, deniz motorlarınızın detaylı bakımı emin ellerde. Her 100 çalışma saati veya her yıl sonunda yaptırılması gereken bakımları titizlikle yapıyoruz.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/ihonda.jpg',
  alt: 'Outboard Motor'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Yedek parça satışı',
  description:
    "Ahtapot Deniz Motorları Servisi olarak, aradığınız tüm yedek parçaları kolaylıkla ulaşmanızı sağlıyoruz.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/isuzuki.jpeg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Sıfır ve ikinci el alım satım',
  description:
    'Deniz motoru almak veya satmak için doğru adrestesiniz. Sıfır veya titizlikle bakımları yapılmış ikinci el motor için doğru adres Ahtapot deniz motorları servisi!',
  buttonLabel: 'Sign Up Now',
  imgStart: '',
  img: 'images/itohatsu.jpeg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Deniz motorları ekspertizliği',
  description:
  'Ahtapot Deniz Motorları Servisi olarak, alacağınız deniz motorlarının ekspertizliğini yapıyoruz. Deniz motorunuz A\'dan Z\' ye incelenip ekspertiz raporu sunulmaktadır.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/imercury.jpeg',
  alt: 'Vault'
};
