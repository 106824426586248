import {BiLira} from 'react-icons/bi';
import React from "react";

const Engine = ({props}) => {
  const shoeName = (
    <div className="shoeName">
      <div>
  <h1 className="big">{props.motorMarka}</h1>
      </div>
      <h3 className="small">{props.motorBeygir}</h3>
    </div>
  );

  const description = (
    <div className="description">
      <h3 className="title">Açıklama</h3>
      <p className="text">
        {props.motorAciklama}
      </p>
    </div>
  );

  const ColorContainer = (
    <div className="color-container">
      <h3 className="title">Model</h3>
      <p className="text">
        {props.motorModel}
      </p>
    </div>
  );

  const SizeContainer = (
    <div className="size-container">
      <h3 className="title">motor saatİ</h3>
      <p className="text">
        {props.motorSaati}
      </p>
    </div>
  );

  const BuySection = (
    <div className="buy-price">
      <h3 className="title">FİYAT</h3>
      <h3 style={{justifyContent:'center'}}>{props.fiyat} <BiLira /></h3>
    </div>
  );

  return (
    <div className="info">
      {shoeName}
      {description}
      {ColorContainer}
      {SizeContainer}
      {BuySection}
    </div>
  );
};

export default Engine;
