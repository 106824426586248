import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from './Data';

import HeroSection from '../../HeroSection';
import HomeLanding from '../../HomeLanding';
import NavbarHome from '../../NavbarHome';
import React from 'react';

function Home() {
  return (
    <>
      <NavbarHome />
      <HomeLanding />
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjTwo} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFour} />
      
    </>
  );
}

export default Home;
