import '../styles/index.scss'

import React, { useEffect } from "react";

import Engine from "../components/Info/Engine";
import ImageGallery from 'react-image-gallery';
import Yatch from '../components/Info/Yatch';

export const images = [
  {
    original: 'http://lorempixel.com/1000/600/nature/1/',
  },
]

const Home = ({props}) => {

  return (
    <div className="Home" >
      <div className="container">
        <div className="card">
          <div className="shoeBackground">
            <ImageGallery
                items={props.images ? props.images : images} 
                showPlayButton={false}
                showBullets={true}
                showThumbnails={false}
            />
          </div>
            {props.type === 0 ? <Engine props={props} /> : <Yatch props={props} />}
        </div>
      </div>
    </div>
  );
};

export default Home;
