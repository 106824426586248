import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import "../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

import * as firebase from 'firebase';

import React, {useEffect} from 'react';

import ImageGallery from 'react-image-gallery';
import Navbar from "../../Navbar";
import { images } from './Data';

function Gallery() {
  let db = firebase.firestore();

  const [list, setList] = React.useState([]);

  const [s, setS] = React.useState(false);

  function getData() {
    
    let imgs = []

    const snapshot = db.collection('galeri').get().then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());

      data.forEach(img => {

        if(img.image != null){ 
          imgs.push({
          original: img.image.src,
          thumbnail: img.image.src
        })
        }
      })

      setS(true);
      setList(imgs);
    });

  }
  
  const fetchData = async () => {

      await getData();
      
    console.log(list);
  };




  useEffect(() => {

    fetchData();
    
  }, [s]);


  return (
    <>
    <Navbar />
    <div className='container'> 
    <ImageGallery 
        items={list} 
        showPlayButton={false}
        showBullets={true}
        autoPlay={true}
    />
    </div>
    </>
  );
}

export default Gallery;
