import './HeroSection2.css';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function HeroSection2({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  description9,
  description10,
  description11,
  description12,
  description13,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section2' : 'home__hero-section2 darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row2'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col2'>
              <div className='home__hero-text-wrapper'>
              <ScrollAnimation animateIn='animate__heartBeat'>

                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn={imgStart === 'start' ? "animate__fadeInRight" : "animate__fadeInLeft"}>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description2}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description3}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description4}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description5}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description6}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description7}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description8}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description9}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description10}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description11}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description12}
                </p>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle2'
                      : 'home__hero-subtitle2 dark'
                  }
                >
                  {description13}
                </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className='col2'>
              <div className='home__hero-img-wrapper2'>
              <ScrollAnimation animateIn={imgStart === 'start' ? "animate__fadeInLeft" : "animate__fadeInRight"}>
                <img src={img} alt={alt} className='home__hero-img2' />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection2;
