import {BiLira} from 'react-icons/bi';
import React from "react";

const Yatch = ({props}) => {
  const shoeName = (
    <div className="shoeName">
      <div>
  <h1 className="big">{props.tekneMarka}</h1>
      </div>
    </div>
  );

  const description = (
    <div className="description">
      <h3 className="title">Açıklama</h3>
      <p className="text">
        {props.tekneAciklama}
      </p>
    </div>
  );

  const ColorContainer = (
    <div className="color-container">
      <h3 className="title">Model</h3>
      <p className="text">
        {props.tekneModel}
      </p>
    </div>
  );

  const SizeContainer = (
    <div className="size-container">
      <h3 className="title">tekne uzunluğu</h3>
      <p className="text">
      {props.tekneUzunlugu}
      </p>
    </div>
  );
  
  const engineCont = (
    <div className="size-container">
      <h3 className="title">Motor özellİklerİ</h3>
      <div className='engineProps' style={{marginTop:5}}>
        <h3 className="entitle">Motor Açıklama: </h3>
        <p className="text">
        {props.motorAciklama}
        </p>
      </div>
      <div className='engineProps' >
        <h3 className="entitle">Marka: </h3>
        <p className="text">
        {props.motorMarka}
        </p>
      </div>
      <div className='engineProps' >
        <h3 className="entitle">Hp: </h3>
        <p className="text">
        {props.motorBeygir}
        </p>
      </div>
      <div className='engineProps' >
        <h3 className="entitle">Model: </h3>
        <p className="text">
        {props.motorModel}
        </p>
      </div>
      <div className='engineProps'>
        <h3 className="entitle">Motor Saati: </h3>
        <p className="text">
        {props.motorSaati}
        </p>
      </div>
      
    </div>
  );

  const BuySection = (
    <div className="buy-price">
      <h3 className="title">FİYAT</h3>
      <div className='engineProps' >
        <h3 >{props.fiyat} </h3>
          <BiLira />
      </div>
    </div>
  );

  return (
    <div className="info">
      {shoeName}
      {description}
      {ColorContainer}
      {SizeContainer}
      {props.motor && engineCont}
      {BuySection}
    </div>
  );
};

export default Yatch;
