import './HeroSection.css';

import { Button } from './Button';
import { Link } from 'react-router-dom';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
            <ScrollAnimation animateIn={imgStart === 'start' ? "animate__fadeInRight" : "animate__fadeInLeft"}>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                
            </ScrollAnimation>
              </div>
            </div>
            
            
            <div className='col'>
              <div className='home__hero-img-wrapper3'>
              <ScrollAnimation animateIn={imgStart === 'start' ? "animate__fadeInLeft" : "animate__fadeInRight"}>
                <img src={img} alt={alt} className='home__hero-img' />
            </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
