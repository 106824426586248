import '../../../App.css';
import '../../ProductCard/styles/index.scss'
import './Products.css'

import * as firebase from 'firebase';

import React, {useEffect} from 'react';

import Home from '../../ProductCard/pages/Home';
import Navbar from '../../Navbar';

//import Firebase from '../../Firebase/firebase';


function Products({getApp}) {

  let db = firebase.firestore();

  const [list, setList] = React.useState([]);

  const [s, setS] = React.useState(false);
  var shoes, shoeBackground, shoeHeight, shoeBackground2;
  // for responsive behaviour
  const changeHeight = () => {
    var x = window.matchMedia("(max-width:1000px)");

    !shoes ? (shoeHeight = 0) : (shoeHeight = shoes[0].offsetHeight);
    try {
      if (x.matches) {
        if (shoeHeight === 0) {
          try {
            setTimeout(changeHeight);
          } catch (error) {
            alert("Something is Wrong!!");
          }
        }
        shoeBackground2.forEach(s =>s.style.height = `${shoeHeight * 0.9}px`);


      } else if (!!shoeBackground) {
        // go back to default
        shoeBackground2.forEach(s =>s.style.height = `${shoeHeight}px`);
      }
    } catch(err) {
      console.log(err)
    }

  };

  function getData() {

    const snapshot = db.collection('motor').get().then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);

      data.forEach(motor => {
        list.push(motor);
        let imgs = []

        // if(motor.image != null){
        //   console.log("$$$$$$$$$$$$$")
        //   console.log((motor.image).src);
        // }
        if(motor.image != null){
          motor.image.forEach(i => {
            console.log(i);
            imgs.push({
              original: i.src,
            })
          })
        }
        list[list.length-1]["images"] = imgs;
        list[list.length-1]["type"] = 0;
      })
      db.collection('tekne').get().then(querySnapshot2 => {
        const ee = querySnapshot2.docs.map(doc => doc.data());
        if(ee == null){
          setS(true);
          setList(list);
          console.log(list);
        }
      });
    });

    const snapshot2 = db.collection('tekne').get().then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);

      data.forEach(motor => {
        list.push(motor);
        let imgs = []

        // if(motor.image != null){
        //   console.log("$$$$$$$$$$$$$")
        //   console.log((motor.image).src);
        // }
        if(motor.image != null){
          motor.image.forEach(i => {
            console.log(i);
            imgs.push({
              original: i.src,
            })
          })
        }
        list[list.length-1]["images"] = imgs;
        list[list.length-1]["type"] = 1;
      })

      setS(true);
      setList(list);
      console.log(list);
    });



  }

  const fetchData = async () => {

      await getData();
  };




  useEffect(() => {
    console.log("@@@@@@@@@");

    shoes = document.querySelectorAll(".shoe");
    shoeBackground = document.querySelector(".shoeBackground");
    shoeBackground2 = document.querySelectorAll(".shoeBackground");
    console.log("@@@@@@@@@");

    fetchData();

  }, [list, s]);



    const Empty = ({props}) => {

      const BuySection = (
        <div className="buy-price">
          <h3 className="title">Henüz eklenmİş ürün bulunmamaktadır.</h3>
        </div>
      );

      return (
        <div className="info">
          {BuySection}
        </div>
      );
    };

    const [textContent, settextContent] = React.useState(null)
    const [copied, setCopied] = React.useState(false)

   const copyToClipboard = async e => {
      window.getSelection().removeAllRanges();
      var range = document.createRange();
      range.selectNode(textContent);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      setCopied( true );
    };

    
  const initRef = c => {settextContent(c)};

  return (
    <>
      <Navbar />
        <div className="Home" >
          <div className="container">
            <div className="card">

        <div className="info">
              <h2>2. el ürünler hakkında detaylı bilgi için iletişim</h2>
              <h3>Gökay Çöl</h3>
              <h3 style={{marginRight:5}}>Telefon:  <a href='tel:+905375886715'>0 (537) 588 67 15</a> </h3>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <h3>E-mail: </h3>
              <div style={{margin:5}}>
              <h3  onClick={copyToClipboard} ref={initRef} >gokaycol@hotmail.com </h3>
              </div>
              </div>
              
              {copied && <p>E-mail kopyalandı.</p>}
        </div>
      </div></div>
      </div>
      {list.length == 0 ? (
        <div className="Home" >
          <div className="container">
            <div className="card">
            <div className="shoeBackground">
              <img src='images/ahtapot-marin.jpeg' alt='Ahtapot Marin'/>
            </div>
              <Empty />
          </div>
          </div>
        </div>
      ) : (
        list.map((list) => {
          return <Home key={list.id} props={list} />;
       })
      )}


    </>
  );
}

export default Products;
