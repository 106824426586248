import './Footer.css';

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';

import { Button } from '../../Button';
import { GiOctopus } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import React from 'react';
import SubscribeCard from "react-subscribe-card";

const mailchimpURL = `https://ahtapotmarin.us2.list-manage.com/subscribe/post?u=0b6f761661c2f76001d59e2df&amp;id=825da45f03`;

const innerCardStyle = `
      padding: 0;
      background-color: #1c2237;
    `;
const outerCardStyle = `
      padding: 0;
      background-color: #1c2237;
    `;
const titleStyle = `
          color: #fff;
          font-family: 'PT Sans', sans-serif;
        `;
const descriptionStyle = `
    color: #fff;
    font-family: 'PT Sans', sans-serif;
   `;     
const subContainerStyle = `
      box-shadow: 0 2px 5px -1px rgba(50,50,93,.25), 0 1px 3px -1px rgba(0,0,0,.3);
      align-items: center;
    `;
const subInputStyle = `
      border-width: 0;
      margin: 0;
    `;
const subButtonStyle = `
      border-radius: 0;
      flex-grow: 1;
      background-color: #fdfdfd;
      color: #000000;
      margin: 10px;
    `;

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          En son haberleri almak için e-mail grubumuza kayıt ol
        </p>
        <p className='footer-subscription-text'>
          İstediğin zaman abonelikten çıkabilirsin.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='E-mail'
            />
            <Button buttonStyle='btn--outline'>Abone Ol</Button>
          </form>
        </div>
        
      </section> */}
      <SubscribeCard
          mailchimpURL={mailchimpURL}
          titleStyle={titleStyle}
          descriptionStyle={descriptionStyle}
          innerCardStyle={innerCardStyle}
          outerCardStyle={outerCardStyle}
          subContainerStyle={subContainerStyle}
          subInputStyle={subInputStyle}
          subButtonStyle={subButtonStyle}
          title='E-mail Grubumuza Kaydol'
          description="En son haberleri almak için mail grubumuza kaydol, istediğin zaman abonelikten çıkabilirsin."
          emailPlaceholder='E-mail'
          buttonText='Abone Ol'
        />
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <h2>Yetkili Markalar</h2>
          <div className='footer-link-items'>
          <Link
              to={
                '//anadolumotormarine.com/'
              }
              target='_blank'
            >
              <img
              alt='Honda Marin'
              src={require('../../../images/logos/honda-marine-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.mercurysport.com/distan-takma-motorlar-C1-P0.html'
              }
              target='_blank'
            >
              <img
              alt='Mercury'
              src={require('../../../images/logos/mercury-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.suzuki.com.tr/tr/marine/'
              }
              target='_blank'
            >
              <img
              alt='Suzuki'
              src={require('../../../images/logos/suzuki-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.tohatsu.com/'
              }
              target='_blank'
            >
              <img
              alt='Tohatsu'
              src={require('../../../images/logos/logo-tohatsu.png')}
            />
            </Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src='images/Ahtapot-Marin-Logo.png' alt='Ahtapot Marin Logo' className='ahtapotlogo' />

            </Link>
          </div>
          <div className='developers'>
          <Link
              className='social-icon-link'
              to='/admin'
            >
          <small className='website-rights'>Ahtapot Marin © 2020</small>
          
          </Link>
          <small className='website-rights'>
            Developed by
          </small>
          <div  className='dev-links-cont'>
            <Link 
              className='devlinks' 
              to='//ertugrulsagdic.com'
              target='_blank'
            >
                Ertuğrul Sağdıç              
            </Link>
            <Link 
              className='devlinks' 
              to='//gokseltokur.com/'
              target='_blank'
            >
              Göksel Tokur
            </Link>
          </div>
          </div>
          <div className='social-icons'>
            
            <Link
              className='social-icon-link'
              to='//instagram.com/ahtapot_marin?igshid=hivvsigxcz2r'
              target='_blank'
              aria-label='Instagram'
            >
              <p className='insta'>Instagram</p> <FaInstagram />
            </Link>
           
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
