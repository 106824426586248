import React, { Component } from 'react';

import { FaMapMarkerAlt } from 'react-icons/fa';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 40.2216939,
      lng: 29.0189047
    },
    zoom: 18
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBoCNG5rvoHTpugE2a-0Ps87kgGCpC_JEc' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <FaMapMarkerAlt
            lat={40.2216939}
            lng={29.0189047}
            size={35}
            color= 'red'
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default SimpleMap;