import './HomeLanding.css'

import { Link } from 'react-router-dom';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function HomeLanding() {
  return (
    <>
    <div
      className='bg'  
    >
        <div className='header1'>
        <div
           className='header'
        >
          <ScrollAnimation animateIn='animate__fadeIn'>

           <h1 className='headertitle'>Ahtapot Marin</h1>
           <h2 className='headersubtitle'>Yetkili Deniz Motorları Servisi </h2>
        </ScrollAnimation>
        
        </div>
        </div>
        <div className='footer-linksh'>
        <div className='footer-link-wrapperh'>
          <h2>Yetkili Markalar</h2>
          <div className='footer-link-itemsh'>
          <Link
              to={
                '//anadolumotormarine.com/'
              }
              target='_blank'
            >
              <img
              alt='Honda Marin'
              src={require('../images/logos/honda-marine-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.mercurysport.com/distan-takma-motorlar-C1-P0.html'
              }
              target='_blank'
            >
              <img
              alt='Mercury'
              src={require('../images/logos/mercury-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.suzuki.com.tr/tr/marine/'
              }
              target='_blank'
            >
              <img
              alt='Suzuki'
              src={require('../images/logos/suzuki-logo.png')}
            />
            </Link>
            <Link
              to={
                '//www.tohatsu.com/'
              }
              target='_blank'
            >
              <img
              alt='Tohatsu'
              src={require('../images/logos/logo-tohatsu.png')}
            />
            </Link>
          </div>
        </div>
      </div>
    </div>
      
    </>
  );
}

export default HomeLanding;
